import React from 'react';

const Pause = (props) => {
	return (
		<svg
			style={{ fill: props.color }}
			x='0px'
			y='0px'
			viewBox='0 0 47.607 47.607'
		>
			<g>
				<path
					d='M17.991,40.976c0,3.662-2.969,6.631-6.631,6.631l0,0c-3.662,0-6.631-2.969-6.631-6.631V6.631C4.729,2.969,7.698,0,11.36,0
		l0,0c3.662,0,6.631,2.969,6.631,6.631V40.976z'
				/>
				<path
					d='M42.877,40.976c0,3.662-2.969,6.631-6.631,6.631l0,0c-3.662,0-6.631-2.969-6.631-6.631V6.631
		C29.616,2.969,32.585,0,36.246,0l0,0c3.662,0,6.631,2.969,6.631,6.631V40.976z'
				/>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	);
};

const Retry = (props) => {
	return (
		<svg
			onClick={props.onClickThis}
			style={{ fill: props.color }}
			x='0px'
			y='0px'
			viewBox='0 0 14.155 14.155'
		>
			<g>
				<path
					d='M12.083,1.887c-0.795-0.794-1.73-1.359-2.727-1.697v2.135c0.48,0.239,0.935,0.55,1.334,0.95
		c1.993,1.994,1.993,5.236,0,7.229c-1.993,1.99-5.233,1.99-7.229,0c-1.991-1.995-1.991-5.235,0-7.229
		C3.466,3.269,3.482,3.259,3.489,3.25h0.002l1.181,1.179L4.665,0.685L0.923,0.68l1.176,1.176C2.092,1.868,2.081,1.88,2.072,1.887
		c-2.763,2.762-2.763,7.243,0,10.005c2.767,2.765,7.245,2.765,10.011,0C14.844,9.13,14.847,4.649,12.083,1.887z'
				/>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	);
};

//
const Flag = (props) => {
	return (
		<svg
			style={{
				fill: !props.enabled
					? props.theme
						? '#000000'
						: '#ffffff'
					: props.color,
			}}
			onClick={props.onClickflag}
			// className={!props.enabled ? 'enabled' : 'd'}
			height='512'
			viewBox='0 0 58 58'
			width='512'
		>
			<g id='Page-1' fill='none' fill-rule='evenodd'>
				<g
					style={{
						fill: !props.enabled
							? props.theme === 'light'
								? '#000000'
								: '#ffffff'
							: props.color,
					}}
					// style={{ fill: props.color }}
					id='037---Waypoint-Flag'
					fill-rule='nonzero'
					transform='translate(0 -1)'
				>
					<path
						id='Shape'
						d='m14.678 58.9507 1.0678-.2984c1.0270794-.287091 1.6269982-1.3523947 1.34-2.3795l-12.2083-43.6888c-.17227193-.6165569-.58242107-1.139423-1.14021438-1.4535673-.5577933-.3141444-1.21753647-.3938324-1.83408562-.2215327l-.1379.0385c-1.28397381.3587434-2.0340279 1.6904218-1.6753 2.9744l12.2086 43.6888c.2870014 1.0271063 1.3522895 1.6270863 2.3794 1.3401z'
					/>
					<path
						id='Shape'
						d='m57.67 28.42c-3.8715209-1.930437-7.4530885-4.3944478-10.64-7.32-.2678864-.245221-.3726619-.6216366-.27-.97 1.579074-5.9738125 2.7517572-12.04771023 3.51-18.18.12-1.02-.43-1.32-1.01-.62-11.38 13.61-31.07-2.49-42.79 9.88.14070884.2634479.25140182.5418575.33.83l7.92 28.36c11.74-12.22 31.36 3.78 42.72-9.8.58-.7.69-1.98.23-2.18z'
					/>
				</g>
			</g>
		</svg>
	);
};

const Pickaxe = (props) => {
	return (
		<svg
			// style={{
			// 	fill: props.color,
			// }}
			onClick={props.onClickflag}
			// className={props.enabled ? 'enabled' : 'd'}
			height='512'
			viewBox='0 0 60 60'
			width='512'
		>
			<g id='Page-1' fill='none' fill-rule='evenodd'>
				<g
					style={{
						fill: props.enabled
							? props.theme === 'light'
								? '#000000'
								: '#ffffff'
							: props.color,
					}}
					id='043---Mining-Game'
					fill-rule='nonzero'
					transform='translate(-1 -1)'
				>
					<path
						id='Shape'
						d='m33.879 2.555-1.789-.895c-1.4815172-.74125473-3.2834346-.14136175-4.025 1.34l-.447.894c-.6414877-.32326008-1.4081785-.2711251-2 .136-3.052-1.374-15.712-6.418-23.87.553-.6420893.49293802-.91552816 1.32961038-.68847288 2.10659974.22705527.77698936.9079877 1.3347711 1.71447288 1.40440026 6.39620072.36041341 12.6516877 2.0216018 18.384 4.882.0455152.7052761.4609583 1.3337669 1.092 1.652l-15.461 30.912c-.65248964 1.2797542-.568645 2.8111165.21966284 4.0119846.78830785 1.2008682 2.16001299 1.8868166 3.59370226 1.7971023 1.4336893-.0897143 2.7091811-.9413134 3.3416349-2.2310869l15.461-30.911c.6322198.3198464 1.3875003.275553 1.978-.116 5.7262431 2.8705311 10.8067997 6.8789 14.931 11.78.5398504.6026647 1.3941668.8123602 2.1517055.528145.7575387-.2842153 1.2630895-1.0041091 1.2732945-1.813145.685-10.707-10.939-17.812-13.871-19.426-.0286935-.71987278-.4488351-1.36639203-1.095-1.685l.448-.894c.3561404-.71180417.4148222-1.53595911.1631277-2.29104256s-.7931329-1.37919833-1.5051277-1.73495744zm-4.025 1.345c.1179198-.23902134.3263779-.42107961.5790952-.50575682.2527174-.08467721.5287816-.06496629.7669048.05475682l1.789.9c.2372731.11864556.4176795.32670923.5015133.57839797.0838337.25168873.064224.52637476-.0545133.76360203l-.448.893-3.581-1.794zm-26.807 2.2c6.923-5.913 18.169-1.773 21.422-.38l-2.669 5.344c-5.8597557-2.88244581-12.23457187-4.56988717-18.753-4.964zm9.108 42.121c-.504599.96777-1.6918991 1.3522971-2.66804074.8640898-.97614168-.4882073-1.38063604-1.6688541-.90895926-2.6530898l15.461-30.909 3.577 1.79zm18.145-31.803-.894-.447-6.26-3.132 3.579-7.154 7.154 3.578-3.579 7.156zm17.443 12.041c-4.2236282-4.9782015-9.3953109-9.0665299-15.214-12.027l2.671-5.345c3.068 1.765 13.126 8.272 12.543 17.372z'
					/>
					<path
						style={{ fill: 'transparent' }}
						id='Shape'
						d='m59.684 11.052c-1.9252656.7147246-3.4980155 2.1487341-4.387 4-1.117.137-3.26.639-4.192 2.5-.1598528.3203946-.1362053.701805.062 1 2.2861979 3.9996701 2.6177263 8.8252511.9 13.1-.1047289.2851447-.0761039.6022221.078.864.251.426 2.34 4.233-1.656 6.631-.2176726.1302357-.3772529.338835-.446.583-.4071472 1.2473972-1.3119619 2.2709236-2.5 2.828-1.546.592-3.568.124-6.008-1.4-.1597358-.1023485-.3452897-.1571476-.535-.158-.084 0-8.132.093-11.578 7.777-2.815678-.798842-5.7968704.5504064-7.055 3.193-2.336-.031-9.506.464-11.337 7.788-.0744852.2986412-.0072954.6149454.1821557.8575209.1894511.2425756.4800544.3843941.7878443.3844791h47c1.1045695 0 2-.8954305 2-2v-47c-.0002439-.3212672-.1548257-.6228543-.4155001-.8106349s-.5956946-.2388857-.9004999-.1373651zm-.684 47.948h-45.627c2.316-5.7 9.206-5.041 9.519-5.006.4463278.0486731.8703829-.2060871 1.037-.623.3923137-1.1108747 1.2316402-2.0068618 2.3145568-2.4708061 1.0829165-.4639442 2.3105781-.4534983 3.3854432.0288061.2498503.1003238.5295994.0956595.7759664-.0129377.2463669-.1085972.4385346-.3119513.5330336-.5640623 1.5085242-4.1839255 5.3607579-7.0699561 9.8-7.342 2.891 1.744 5.42 2.22 7.521 1.411 1.6562459-.7094594 2.9479037-2.0687376 3.572-3.759 3.258-2.131 3.767-5.566 2.262-8.733 1.6598672-4.5537309 1.3300247-9.5953499-.909-13.894.7940573-.6544934 1.787094-1.0194767 2.816-1.035.4306074.0001037.8129281-.2754572.949-.684.381756-1.0815094 1.1007165-2.0118463 2.051-2.654z'
					/>
					<path
						style={{ fill: 'transparent' }}
						id='Shape'
						d='m51.857 49.441-1.905-.6c-1.0523476-.3340453-2.176313.2478563-2.511 1.3l-.6 1.907c-.3333958 1.0520394.2483739 2.1753026 1.3 2.51 2.056.65 2.107.7 2.512.7.8705258-.0034313 1.638847-.5695628 1.9-1.4l.6-1.907c.333729-1.0508705-.245979-2.1736076-1.296-2.51zm-1.2 3.814-1.907-.6.6-1.907 1.907.6z'
					/>
					<path
						style={{ fill: 'transparent' }}
						id='Shape'
						d='m39.049 51.887c-.3346974-1.0516261-1.4579606-1.6333958-2.51-1.3l-1.906.6c-1.0516261.3346974-1.6333958 1.4579606-1.3 2.51l.6 1.906c.334687 1.0521437 1.4586524 1.6340453 2.511 1.3l1.907-.6c1.0516261-.3346974 1.6333958-1.4579606 1.3-2.51zm-3.211 3.113-.6-1.906 1.907-.6.6 1.907z'
					/>
					<path
						style={{ fill: 'transparent' }}
						id='Shape'
						d='m43 47c-.5522847 0-1 .4477153-1 1v1c0 .5522847.4477153 1 1 1s1-.4477153 1-1v-1c0-.5522847-.4477153-1-1-1z'
					/>
					<path
						style={{ fill: 'transparent' }}
						id='Shape'
						d='m26.293 54.293-1 1c-.3789722.3923789-.3735524 1.0160848.0121814 1.4018186s1.0094397.3911536 1.4018186.0121814l1-1c.3789722-.3923789.3735524-1.0160848-.0121814-1.4018186s-1.0094397-.3911536-1.4018186-.0121814z'
					/>
					<path
						style={{ fill: 'transparent' }}
						id='Shape'
						d='m55.707 43.293c-.3923789-.3789722-1.0160848-.3735524-1.4018186.0121814s-.3911536 1.0094397-.0121814 1.4018186c1.06 1.061 1.187 1.293 1.707 1.293.4043959-.0000863.7689341-.2437275.923678-.6173454.1547439-.373618.0692299-.8036603-.216678-1.0896546z'
					/>
					<path
						style={{ fill: 'transparent' }}
						id='Shape'
						d='m34 34.784c0 3.095 2.017 4.984 5.312 3.9 1.0799599-.2458729 1.9912401-.9660782 2.48-1.96.591-1.429-.2-2.989-.9-4.366-1.518-2.983-6.892-.523-6.892 2.426zm5.944 1.18c-.265.639-2.381 1.425-3.237.8-.5205275-.5206695-.780019-1.2473925-.707-1.98 0-1.071 2.678-2.364 3.108-1.515.504.992 1.076 2.116.836 2.695z'
					/>
				</g>
			</g>
		</svg>
	);
};

export { Pause, Retry, Flag, Pickaxe };
